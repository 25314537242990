import { Fragment, useEffect, useRef } from 'react';
import { Input, InputProps } from '@material-tailwind/react/components/Input';
import tw from 'tailwind-styled-components';
import { Typography } from 'components/atoms/typography';
import Alert from 'public/icons/figma/alert.svg';

export interface InputTextFieldProps extends Omit<InputProps, 'ref'> {
  label?: string;
  onChange?: (e: any) => any;
  error?: boolean;
  showErrorMessage?: boolean;
  disabled?: boolean;
  required?: boolean;
  icon?: any;
  ariaLabel?: string;
  message?: string;
  value?: string;
}
const InputTextFieldContainer = tw.div<{ $error: boolean }>`
    ${(p) =>
      p.$error
        ? `
      flex
      flex-col
      items-start
      gap-[4px]
      `
        : ''}
`;

const InputTextFieldErrorContainer = tw.div`
  flex
  flex-row
  gap-1
`;

const ErrorComponent = ({ message = 'Error message' }: { message?: string }) => {
  return (
    <InputTextFieldErrorContainer>
      <Alert className="text-error" />
      <span className="pt-[5px]">
        <Typography variant="body-small-bold" className="!text-grey-500">
          {message}
        </Typography>
      </span>
    </InputTextFieldErrorContainer>
  );
};

const InputTextField = ({
  label = 'Label',
  onChange,
  disabled = false,
  error = false,
  showErrorMessage = true,
  required = false,
  icon,
  ariaLabel = '',
  message,
  value,
  ...rest
}: InputTextFieldProps) => {
  const ref = useRef<any>(null);

  // FIXME: Getting NotFoundError: Failed to execute 'insertBefore' on 'Node': The node before which the new node is to be inserted is not a child of this node.
  // useEffect(() => {
  //   ref.current.lastChild.innerHTML = `
  //           <span class="input-textfield-label transition-all ease-in duration-100 px-[6px] h-[15px]
  //           ${icon ? 'indent-5 [&_span.text-resin]:indent-0' : ''}
  //           ${disabled ? '!text-grey-300' : '!text-grey-400'}">
  //           ${ref.current.lastChild.innerHTML}
  //           </span>
  //       `;
  // }, []);

  useEffect(() => {
    if (ref?.current?.lastChild?.firstElementChild) {
      // label text
      ref.current.lastChild.firstElementChild.className = disabled
        ? ref.current.lastChild.firstElementChild.className?.replace('!text-grey-400', '!text-grey-300')
        : ref.current.lastChild.firstElementChild.className?.replace('!text-grey-300', '!text-grey-400');
      // label icon
      ref.current?.firstChild?.lastChild?.firstChild?.setAttribute('fill', disabled ? '#BDC2A6' : '#6F7263');
    }
  }, [disabled]);

  const Icon = icon ? icon : Fragment;
  return (
    <InputTextFieldContainer $error={error}>
      <Input
        defaultValue={value}
        className={icon ? 'pl-9' : ''}
        ref={ref}
        label={label}
        crossOrigin=""
        onChange={onChange}
        disabled={disabled}
        error={error}
        required={required}
        aria-label={ariaLabel}
        icon={<Icon />}
        labelProps={{
          className: icon && !value ? 'pl-7' : '',
        }}
        {...rest}
      />
      {error && showErrorMessage ? <ErrorComponent message={message} /> : <></>}
    </InputTextFieldContainer>
  );
};

export default InputTextField;
